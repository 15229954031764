import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import Brightness5Icon from '@material-ui/icons/Brightness5';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import Wand from './images/wand.png'
function Header() {
    const [config, setconfig] = React.useState({
        dark: false
    })
    const togledark = () => {
        setconfig({ ...config, dark: !config.dark })
        localStorage.setItem('dark', !config.dark)
        window.location.reload(false)
    }
    React.useEffect(() => {
        setconfig(pv => ({ ...pv, dark: localStorage.getItem('dark') === 'true' ? true : false }))
    }, [])
    return (
        <>
            <AppBar variant="elevation" elevation={0} color="inherit" position="sticky">
                <Toolbar>
                    <Typography component={Link} to="/" style={{ flexGrow: 1 }} variant="h6" >

                    </Typography>
                    <IconButton aria-label="Dark mode toggle" onClick={togledark}>
                        {config.dark ?
                            <Brightness5Icon /> : <Brightness2Icon />
                        }
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={`logo_box`}>
                <Link to='/'>
                <img src={Wand} alt="Wand Logo" />
                    
                </Link>

            </div>
        </>
    )
}

export default Header
